import { jwtDecode } from "jwt-decode";
import React from "react";
import { NavLink } from "react-router-dom";

const Sidebar = () => {
  const token = localStorage.getItem("token");
  const decodedToken = token ? jwtDecode(token) : {};
  const role = decodedToken?.role;

  const menuItems = [
    {
      roles: ["admin"],
      name: "Admin",
      link: "/admin",
    },
    {
      roles: ["client"],
      name: "Block",
      link: "/block",
    },
    {
      roles: ["admin"],
      name: "Block",
      link: "/ablock",
    },
    {
      roles: ["client"],
      name: "Commission",
      link: "/commission",
    },
    {
      roles: ["admin"],
      name: "Commission",
      link: "/acommission",
    },
    {
      roles: ["client", "admin"],
      name: "Personal assistance",
      link: "/assistance",
    },
    // {
    //   roles: ["admin"],
    //   name: "Personal assistance",
    //   link: "/admin-assistance",
    // },
    {
      roles: ["admin"],
      name: "People",
      link: "/people",
    },
    {
      roles: ["admin"],
      name: "Contact Details",
      link: "/contactDetails",
    },
    // {
    //   roles: ["admin"],
    //   name: "Chat",
    //   link: "/chat",
    // },
    {
      roles: ["admin", "client"],
      name: "Ticket",
      link: "/ticket",
    },
  ];

  // Filter items based on roles
  const filteredMenu = menuItems.filter((item) => item.roles.includes(role));

  return (
    <aside className="aside">
      {filteredMenu.map((item, i) => (
        <NavLink
          to={item.link}
          className={({ isActive }) => (isActive ? "active" : "aside-link")}
          key={i}
        >
          {item.name}
        </NavLink>
      ))}
    </aside>
  );
};

export default Sidebar;
