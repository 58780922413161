import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import "./ticket.css";
import AxiosInstance from "../../api/api";
import { toast } from "react-toastify";
import { jwtDecode } from "jwt-decode";
const TicketList = () => {
  const [tickets, setTickets] = useState([]);
  const [ticket, setTicket] = useState({
    title: "",
    description: "",
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const ticketsPerPage = 10;
  const [user, setUser] = useState({});
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      // Decode the JWT token to get the userId
      const decodedToken = jwtDecode(token);
      setUser(decodedToken);
    }
  }, []);
  const getTickets = (page = 1) => {
    AxiosInstance.get(`/api/v1/ticket?page=${page}&limit=${ticketsPerPage}`)
      .then((res) => {
        setTickets(res.data.ticket);
        setTotalPages(Math.ceil(res.data.count / ticketsPerPage));
      })
      .catch((e) => toast.error(`${e?.response?.data?.message || e?.message}`));
  };

  useEffect(() => {
    getTickets(currentPage);
  }, [currentPage]);

  const handleCreateTicket = () => {
    const { title, description } = ticket;
    AxiosInstance.post("/api/v1/ticket", { ticket: title, description })
      .then((res) => {
        if (res.data.success) {
          toast.success(res.data.message);
          setTicket({
            title: "",
            description: "",
          });
          getTickets();
        }
      })
      .catch((e) => toast.error(`${e?.response?.data?.message || e?.message}`));
  };

  const handleEDit = (id, status) => {
    AxiosInstance.patch(`/api/v1/ticket/${id}`, { status })
      .then((res) => {
        if (res.data.success) {
          toast.success(res.data.message);
          getTickets(currentPage);
        }
      })
      .catch((e) => toast.error(`${e?.response?.data?.message || e?.message}`));
  };
  const handleDelete = (id) => {
    AxiosInstance.delete(`/api/v1/ticket/${id}`)
      .then((res) => {
        if (res.data.success) {
          toast.success(res.data.message);
          getTickets(currentPage);
        }
      })
      .catch((e) => toast.error(`${e?.response?.data?.message || e?.message}`));
  };
  return (
    <div className="container-tic">
      <div className="ticket-app-container">
        <div className="ticket-form">
          <h2>Create New Ticket</h2>
          <input
            type="text"
            placeholder="Title"
            value={ticket.title}
            onChange={(e) => setTicket({ ...ticket, title: e.target.value })}
          />
          <textarea
            placeholder="Description"
            value={ticket.description}
            rows="4"
            // cols="50"
            className="tictket-textare"
            onChange={(e) =>
              setTicket({ ...ticket, description: e.target.value })
            }
          />
          <button className="btn-submit" onClick={handleCreateTicket}>
            Submit Ticket
          </button>
        </div>
        <div className="tickets">
          {tickets.map((item) => (
            <div className="ticket" key={item._id}>
              <h3>{item.ticket}</h3>
              {user?.role === "admin" && (
                <h3>
                  {item.user.email}-{item.user.name}
                </h3>
              )}
              <p>{item.description}</p>
              <span className={`status ${item.status}`}>{item.status}</span>
              <small>{new Date(item.createdAt).toLocaleString()}</small>
              {user?.role === "admin" && (
                <div className="actions">
                  <button onClick={() => handleEDit(item._id, "received")}>
                    received
                  </button>
                  <button onClick={() => handleEDit(item._id, "resolved")}>
                    resolved
                  </button>
                  <button onClick={() => handleDelete(item._id)}>Delete</button>
                </div>
              )}
            </div>
          ))}
        </div>
        {totalPages > 1 && (
          <div className="pagination">
            {Array.from({ length: totalPages }, (_, i) => (
              <button
                key={i}
                className={currentPage === i + 1 ? "active" : ""}
                onClick={() => setCurrentPage(i + 1)}
              >
                {i + 1}
              </button>
            ))}
          </div>
        )}
        {/* <div className="background-container">
        {[...Array(15)].map((_, index) => (
          <motion.div
            className="particle"
            key={index}
            initial={{
              opacity: 0,
              y: Math.random() * 100,
              x: Math.random() * window.innerWidth,
            }}
            animate={{
              opacity: [0.2, 1, 0.2],
              y: [Math.random() * -500, Math.random() * 500],
              x: [
                Math.random() * window.innerWidth,
                Math.random() * window.innerWidth,
              ],
            }}
            transition={{
              duration: Math.random() * 10 + 5,
              repeat: Infinity,
              ease: "easeInOut",
            }}
          ></motion.div>
        ))}
      </div> */}
      </div>
    </div>
  );
};

export default TicketList;
